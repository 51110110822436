import type { Tracking } from '@dce-front/hodor-types/api/v2/common/dto/tracking/definitions';
import classNames from 'classnames';
import type { JSX } from 'react';
import { ButtonShapes } from '../../../constants/button';
import { TemplateTypes } from '../../../constants/templateTypes';
import { useTranslation } from '../../../lang';
import LoadableLiveGridFavoritesContainer from '../../../templates/LiveGridFavorites';
import Button from '../../Button/Button';
import LiveGridModal from '../LiveGridModal/LiveGridModal';
import styles from './LiveGridEditFavoritesChannels.css';

export const LiveGridFavoritesButtonTypes = {
  DEFAULT: 'DEFAULT',
  EMPTY_STATE: 'EMPTY_STATE',
} as const;

export type LiveGridFavoritesButtonTypes =
  (typeof LiveGridFavoritesButtonTypes)[keyof typeof LiveGridFavoritesButtonTypes];

export type LiveGridFavoritesButtonProps = {
  openModal?: () => void;
  type?: LiveGridFavoritesButtonTypes;
};

function LiveGridFavoritesButton({
  openModal = () => null,
  type = LiveGridFavoritesButtonTypes.DEFAULT,
}: LiveGridFavoritesButtonProps): JSX.Element {
  const { t } = useTranslation();

  switch (type) {
    case LiveGridFavoritesButtonTypes.EMPTY_STATE:
      return (
        <Button
          className={styles.liveGridEditFavoritesButton}
          color={TemplateTypes.SECONDARY}
          handler={() => openModal()}
          shape={ButtonShapes.ROUND}
          text={t('LiveGrid.favorites.editButtonEmptyState')}
        />
      );
    case LiveGridFavoritesButtonTypes.DEFAULT:
    default:
      return (
        <Button
          className={classNames(
            styles.liveGridEditFavoritesButton,
            styles['liveGrid--focus'],
          )}
          color={TemplateTypes.DARK}
          handler={() => openModal()}
          shape={ButtonShapes.ROUND}
          text={t('LiveGrid.favorites.editButton')}
        />
      );
  }
}

export type LiveGridEditFavoritesChannelsProps = {
  liveGridPageTracking?: Tracking;
  refetchLiveGridRequest?: () => void;
  type?: LiveGridFavoritesButtonTypes;
};

function LiveGridEditFavoritesChannels({
  liveGridPageTracking,
  refetchLiveGridRequest = () => null,
  type = LiveGridFavoritesButtonTypes.DEFAULT,
}: LiveGridEditFavoritesChannelsProps): JSX.Element {
  return (
    <LiveGridModal
      modalContent={
        <LoadableLiveGridFavoritesContainer
          liveGridPageTracking={liveGridPageTracking}
          refetchLiveGridRequest={refetchLiveGridRequest}
        />
      }
      triggerModalElement={<LiveGridFavoritesButton type={type} />}
    />
  );
}

export default LiveGridEditFavoritesChannels;
