import { Media, MediaFooter } from '@dce-front/dive';
import type { ApiV2Context } from '@dce-front/hodor-types/api/v2/common/dto/definitions';
import type { ApiV2LiveGridFeature } from '@dce-front/hodor-types/api/v2/live_grid/definitions';
import type { ApiV2BroadcastChannel } from '@dce-front/hodor-types/api/v2/page/dtos/display_templates/live_grid/definitions';
import { Binder, useStore } from '@dce-front/one-navigation';
import { DEFAULT_RATIO, isRatio, Ratio } from '@dce-front/onewebapp-utils';
import { Template } from '@dce-front/sdk-hodor';
import classNames from 'classnames';
import { useCallback, useEffect, useMemo, type JSX } from 'react';
import { useSelector } from 'react-redux';
import LazyLoader from '../../../components/LazyLoader/LazyLoader';
import LiveGridCard from '../../../components/LiveGrid/LiveGridCard/LiveGridCard';
import LiveGridFeatureCard from '../../../components/LiveGrid/LiveGridFeatureCard/LiveGridFeatureCard';
import { ProspectLoginMessageConnected } from '../../../components/LiveGrid/ProspectLoginMessage/ProspectLoginMessageConnected';
import { DEFAULT_PLACEHOLDER_NUMBER } from '../../../constants/common';
import { LIVE_GRID_MINIMUM_CONTENT_VIRTUALISATION } from '../../../constants/liveGrid';
import { PlayerPlatform } from '../../../constants/playerPlatforms';
import {
  getLazyLoaderContentGridOptions,
  StrateMode,
} from '../../../constants/strates';
import { getPublicConfig } from '../../../helpers/config/config-helper';
import { useAppDispatch } from '../../../helpers/hooks/useAppDispatch';
import { useBinderMiddleware } from '../../../helpers/hooks/useBinderMiddleware';
import { useInvariantSelector } from '../../../helpers/hooks/useInvariantSelector';
import {
  formatMultiLivePlayerData,
  useLiveChannelsFilter,
} from '../../../helpers/liveTV/liveTV-helper';
import {
  getMiddlewareLiveGrid,
  getMiddlewareStratePerso,
} from '../../../helpers/oneNavigation/middleware';
import { useTranslation } from '../../../lang';
import {
  getFeatureToggleMultiLiveSelector,
  userStatusSelector,
} from '../../../store/slices/application-selectors';
import { launchPlayerFullScreen } from '../../../store/slices/player-thunk';
import { locationSearchChannelSelector } from '../../../store/slices/routing-selectors';
import styles from './LiveGridTemplate.css';
import { LiveGridVirtual } from './LiveGridVirtual';

export type LiveGridTemplateProps = {
  channels: ApiV2BroadcastChannel[];
  context?: ApiV2Context;
  displayPlaceholderChannels?: boolean;
  features?: ApiV2LiveGridFeature[];
  isAdult?: boolean;
  isPerso?: boolean;
  ratio?: Ratio;
  refetchLiveGridRequest: () => void;
  isLoading?: boolean;
};

function LiveGridTemplate({
  channels,
  context,
  displayPlaceholderChannels = false,
  features = [],
  isAdult,
  isPerso = false,
  ratio = DEFAULT_RATIO,
  refetchLiveGridRequest,
  isLoading,
}: LiveGridTemplateProps): JSX.Element | null {
  const dispatch = useAppDispatch();
  const userStatus = useSelector(userStatusSelector);
  const isProspect = userStatus === getPublicConfig().user_status.prospect;

  const { t } = useTranslation();

  const isFeatMultiLive = useInvariantSelector(
    getFeatureToggleMultiLiveSelector,
  );

  const epgIDsToLaunchOnMount = useSelector(locationSearchChannelSelector);

  const imageRatio = isRatio(DEFAULT_RATIO) ? DEFAULT_RATIO : Ratio.Ratio169;

  const filteredChannels = useLiveChannelsFilter(
    channels,
    refetchLiveGridRequest,
    displayPlaceholderChannels,
  );

  // If a channel parameter is in the URL, we launch that channel
  useEffect(() => {
    if (epgIDsToLaunchOnMount) {
      if (epgIDsToLaunchOnMount.length === 1) {
        const channelToLaunch = {
          channel: { epgID: epgIDsToLaunchOnMount[0] },
        };
        dispatch(
          launchPlayerFullScreen({
            data: channelToLaunch,
            type: StrateMode.LiveTv,
          }),
        );
      } else if (epgIDsToLaunchOnMount.length > 1) {
        const playerData = formatMultiLivePlayerData(epgIDsToLaunchOnMount);
        dispatch(
          launchPlayerFullScreen({
            data: playerData,
            type: PlayerPlatform.Multi,
          }),
        );
      }
    }
  }, [dispatch, epgIDsToLaunchOnMount]);

  const wrapCard = useCallback(
    (element: JSX.Element, key?: string | number) => (
      <li
        className={classNames(
          styles.LiveGridTemplate__gridItem,
          styles['liveGrid--focus'],
        )}
        key={String(key)}
      >
        {element}
      </li>
    ),
    [],
  );

  const listToRender = useMemo<(ApiV2BroadcastChannel | undefined)[]>(() => {
    if (!filteredChannels.length || isLoading) {
      return Array.from({ length: DEFAULT_PLACEHOLDER_NUMBER });
    }
    return filteredChannels;
  }, [isLoading, filteredChannels]);

  const oneNavStore = useStore();
  useEffect(() => {
    if (!$_BUILD_RENDERMODE_CSR) {
      return;
    }
    const activeLayer = oneNavStore.getActiveLayer();
    activeLayer?.focusDefault();
    if (isPerso && activeLayer?.current && activeLayer?.currentBinder) {
      activeLayer.currentBinder.callFocusedHook(activeLayer.current);
    }
  }, [listToRender, isPerso, oneNavStore]);

  const multiLiveFeature = features.find(
    (feature) => feature?.onClick?.displayTemplate === Template.MultiLiveSetup,
  );
  const hasMultiLiveFeature = isFeatMultiLive
    ? Boolean(multiLiveFeature)
    : false;
  const { increment, initialDisplayCount } = getLazyLoaderContentGridOptions(
    {},
  );

  const middlewareStratePerso = useBinderMiddleware(getMiddlewareStratePerso);
  const middlewareLiveGrid = useBinderMiddleware(getMiddlewareLiveGrid);

  if (
    $_BUILD_RENDERMODE_CSR &&
    listToRender.length > LIVE_GRID_MINIMUM_CONTENT_VIRTUALISATION
  ) {
    return (
      <Binder
        middleware={isAdult ? middlewareStratePerso : middlewareLiveGrid}
        forceFocusOnMount={isAdult}
      >
        <LiveGridVirtual
          ratio={ratio}
          listToRender={listToRender}
          isPerso={isPerso}
          hasMultiLiveFeature={hasMultiLiveFeature}
          multiLiveFeature={multiLiveFeature}
          displayPlaceholderChannels={displayPlaceholderChannels}
          context={context}
        />
      </Binder>
    );
  }

  return (
    <Binder
      middleware={isAdult ? middlewareStratePerso : middlewareLiveGrid}
      forceFocusOnMount={isAdult}
    >
      <ul
        data-ratio={imageRatio}
        className={classNames(styles.LiveGridTemplate__grid, 'liveGrid')}
        aria-label={t('LiveGrid.ariaLabel')}
      >
        <LazyLoader
          initialDisplayCount={initialDisplayCount}
          loadMoreCount={increment}
          enableLazyLoad={!!filteredChannels?.length}
        >
          {/* Multi-live setup card */}
          {hasMultiLiveFeature &&
            wrapCard(
              <LiveGridFeatureCard
                displayPlaceholderChannels={displayPlaceholderChannels}
                feature={multiLiveFeature}
                isPerso={isPerso}
                ratio={imageRatio}
              />,
              multiLiveFeature?.contentID,
            )}

          {/* Channels */}
          {listToRender.map((channel, index: number) => {
            const id =
              (typeof channel !== 'undefined' && channel?.epgID) || index;

            const trackingContext: ApiV2Context | undefined = context
              ? { ...context, context_list_position: index + 1 }
              : undefined;

            return wrapCard(
              channel ? (
                <LiveGridCard
                  channel={channel}
                  context={trackingContext}
                  ratio={ratio}
                />
              ) : (
                <Media
                  aspectRatio={ratio}
                  showPlaceholderAnim={!$_BUILD_RENDERMODE_CSR}
                  footer={
                    <MediaFooter
                      title=""
                      subtitle=""
                      fixedHeight="titleAndSubtitle"
                    />
                  }
                />
              ),
              id,
            );
          })}
        </LazyLoader>
      </ul>
      {isProspect && (
        <div className={styles.LiveGridTemplate__prospectLoginMessageWrap}>
          <ProspectLoginMessageConnected />
        </div>
      )}
    </Binder>
  );
}

export default LiveGridTemplate;
