import {
  KEY_BACK,
  Layer,
  useActiveLayer,
  useKeyCatcher,
  useStore,
} from '@dce-front/one-navigation';
import { ModalV2 } from '@dce-front/sharedcomponent';
import type { JSX, ReactElement } from 'react';
import { cloneElement, useCallback, useState } from 'react';
import { LAYER_LIVEGRID_MODAL } from '../../../helpers/oneNavigation/layers';

export type LiveGridModalProps = {
  triggerModalElement: ReactElement<{ openModal: () => void }>;
  modalContent: ReactElement<{
    closeModal: () => void;
    onFocusable: () => void;
  }>;
};

function LiveGridModal({
  modalContent,
  triggerModalElement,
}: LiveGridModalProps): JSX.Element {
  const [isModalOpen, setOpenModal] = useState(false);
  const closeModal = () => setOpenModal(false);
  const openModal = () => setOpenModal(true);

  // Maybe used by modalContent component to trigger refocus when DOM is mounted in async fashion
  const store = useStore();
  const handleFocusable = useCallback(() => store.focusDefault(), [store]);

  useKeyCatcher(KEY_BACK, closeModal, LAYER_LIVEGRID_MODAL);
  useActiveLayer(LAYER_LIVEGRID_MODAL, isModalOpen);

  return (
    <>
      {cloneElement(triggerModalElement, { openModal })}
      {isModalOpen && (
        <ModalV2
          isTvDevice={$_BUILD_RENDERMODE_CSR}
          onClose={() => closeModal()}
          size={$_BUILD_RENDERMODE_CSR ? 'fullscreen' : 'immersive'}
          hideCloseButton={$_BUILD_RENDERMODE_CSR}
          preferScrollableBody={!$_BUILD_RENDERMODE_CSR}
          id="modal-scroll-container"
          hidePadding
        >
          <Layer layer={LAYER_LIVEGRID_MODAL}>
            {cloneElement(modalContent, {
              closeModal,
              onFocusable: handleFocusable,
            })}
          </Layer>
        </ModalV2>
      )}
    </>
  );
}

export default LiveGridModal;
